<template>
  <div style="width: 100%; overflow: hidden; position: relative" id="parent">
    <div class="left_tab_parent" :style="{ height: tab_height }">
      <div class="left_tab">
        <div
          v-for="(item, index) in tab_list"
          :key="index"
          :class="{ isChoose: isChoose == index }"
          @click="showTab(index)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="right_content_parent">
      <div class="right_content">
        <component
          :is="componentsList[isChoose]"
          v-if="forceUpdate"
        ></component>
      </div>

    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "EnterpriseSchoolConnection",
  mounted() {
    this.changeHeight();
  },
  watch: {
    startTime() {
      let self = this;
      this.endOption = {
        disabledDate(date) {
          if (self.startTime) {
            return date && date.valueOf() < self.startTime;
          }
        },
      };
    },
    endTime() {
      let self = this;
      this.startOption = {
        disabledDate(date) {
          if (self.endTime) {
            return date && date.valueOf() > self.endTime;
          }
        },
      };
    },
  },
  components: {
    EnterpriseDemand: () =>
      import(
        "@/components/science/EnterpriseSchoolConnection/EnterpriseDemand.vue"
      ),
    EnterpriseInformationMatching: () =>
      import(
        "@/components/science/EnterpriseSchoolConnection/EnterpriseInformationMatching.vue"
      ),
  },

  data() {
    return {
      forceUpdate: true,
      componentsList: ["EnterpriseDemand", "EnterpriseInformationMatching"],
      startTime: "",
      endTime: "",
      xqmc: "",
      sbmc: "",
      xqlx: "",
      data7: [
        {
          name: "John Brown",
          age: 18,
          address: "New York No. 1 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
        {
          name: "Jon Snow",
          age: 26,
          address: "Ottawa No. 2 Lake Park",
        },
      ],
      columns12: [
        {
          title: "Name",
          slot: "name",
        },
        {
          title: "Age",
          key: "age",
        },
        {
          title: "Address",
          key: "address",
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
        },
      ],
      tab_height: 0,
      tab_list: ["企业需求", "企业信息匹配"],
      isChoose: 0,
      contentIndex: 0,
      startOption: {},
      endOption: {},
      keyword: "",
    };
  },
  methods: {
    showTab(index) {
      this.forceUpdate = false;
      this.isChoose = index;
      this.$nextTick(() => {
        this.forceUpdate = true;
        this.changeHeight();
      });

      scrollTo(0, 0);
    },
    changeHeight() {
      let that = this;
      this.$nextTick(() => {
        that.tab_height =
            Number($(".right_content_parent").height() + 180) >
            Number(document.body.clientHeight) - 101
                ? $(".right_content_parent").height() + 174 + "px"
                : Number(document.body.clientHeight) - 101 + "px";
      });
    },
  },
};
</script>

<style scoped lang="scss">
.ivu-select-selection {
  border-radius: 0 !important;
}
.arrow_ld {
  display: inline-block;
  position: absolute;
  right: 0;
  & > span {
    display: inline-block;
    height: 20px;
    width: 20px;
    background-color: rgb(54, 194, 207);
    text-align: center;
    line-height: 20px;
    color: white;
  }
}
.qiye,
.qiye td {
  border: 1px solid rgb(54, 194, 207);
}
.qiye {
  border-bottom: none;
  border-right: none;
}
.qiye td {
  border-top: none;
  border-left: none;
}

.qiye td:nth-child(1) {
  width: 100px;
  text-align: right;
}

.qiye td:nth-child(2) {
  width: 310px;
}

.qiye td:nth-child(3) {
  width: 130px;
  text-align: right;
}

.qiye td:nth-child(4) {
  width: 260px;
}

.span_cg {
  font-size: 18px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.span_yyjs {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  width: 60px;
  border-width: 1px;
  background-color: rgb(254, 164, 100);
  opacity: 0.8;
  overflow: hidden;
  position: absolute;
  right: 20px;
}

.ivu-col {
  border: none !important;
}

.neirong {
  margin-left: 5px;
  .nei_rong_box {
    height: 620px;
    width: 100%;
    border-bottom: 2px dotted #f5f5f5;
  }
  p {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 14px;
    color: black;
  }
}

.bottom_style {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -110px;
}

.common_liucheng:nth-child(1) {
  border: none;
}

.common_liucheng {
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  & > span {
    display: block;
    padding-left: 5px;
    padding-top: 38px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    color: rgb(51, 51, 51);
    margin-bottom: 30px;
  }
}

.next_style {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_real_content {
  margin-bottom: 50px;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
}

.right_header {
  width: 95%;
  padding-top: 20px;
  height: 140px;
  position: relative;
  margin-bottom: 40px !important;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted #f5f5f5;
  overflow: hidden;
  span {
    display: block;
    margin-bottom: 20px;
  }
}

.button_style {
  display: inline-block;
  background-color: rgb(54, 194, 207);
  width: 100px;
  height: 40px;
  z-index: 51;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: white;
}

.input_search,
.input_search:focus {
  border-width: 1px;
  border-color: rgb(55, 197, 207);
  border-style: solid;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  width: 300px;
  height: 40px;
  outline: none;
}

.isChoose {
  color: #37c5cf;
}

.right_content {
  width: 90%;
  border-width: 1px;
  border-color: rgb(218, 226, 229);
  border-style: solid;
  background-color: rgb(255, 255, 255);
  min-width: 900px;
  height: auto;
}

.right_content_parent {
  box-sizing: border-box;
  width: 80%;
  min-width: 960px;
  float: left;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  position: relative;
}

.left_tab_parent {
  float: left;
  min-width: 240px;
  width: 20%;
  background-color: rgb(250, 250, 252);
  .left_tab {
    margin-top: 60px;
    div {
      width: 210px;
      margin: 0 auto;
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      box-sizing: border-box;
      padding-left: 31px;
      margin-bottom: 20px;
      border-width: 1px;
      border-color: rgb(235, 235, 235);
      border-style: solid;
      background-color: rgb(255, 255, 255);
      cursor: pointer;
    }
  }
}
</style>
